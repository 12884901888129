<template>
    <vx-card :title="'Fill Unloading Items'">
        <div class="vx-row mb-6" style="width: 50%">
            <vs-button class="ml-4 mt-2" color="danger" icon-pack="feather" icon="icon-arrow-left"
                @click="handleBack()">Back</vs-button>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Item Request Document</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="reqCode" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Unloading Document Number</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="unlCode" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Territory</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="selectedTerritory ? selectedTerritory.label : ''" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Warehouse</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="selectedWarehouse ? selectedWarehouse.label : ''" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Sales</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="selectedSales ? selectedSales.label : ''" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Loading Date</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="formatDate(loadingDate)" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Unloading Date</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <vs-input :value="formatDate(unloadingDate)" :readonly="true" class="w-full"></vs-input>
            </div>
        </div>
        <div class="vx-row mb-6">
            <div class="vx-col w-full">
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th width="30%">SKU Code</th>
                            <th width="10%">Qty Loading (UOM)</th>
                            <th width="10%">Qty Sales (UOM)</th>
                            <th width="15%">Unload HU</th>
                            <th width="10%">Qty UOM</th>
                            <th width="10%">Unload Qty</th>
                            <th width="10%">Remain Qty (UOM)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(dataLine, index) in dataLines" :key="index" style="padding-bottom: 5px">
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="dataLine.item_name" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="dataLine.qty_loading" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="dataLine.qty_sales" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <v-select
                                    :filterable="false"
                                    :options="dataLine.ul_option_hu"
                                    v-model="dataLine.ul_selected_hu"
                                    :clearable="false"
                                    @input="(val) => { onChangeHu(index, val); }"
                                    :disabled="dataLine.qty_loading == dataLine.qty_sales"
                                />
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-if="renderUom" class="w-full" type="text" v-model="dataLine.qty_uom" :readonly="true"/>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input 
                                    type="number" 
                                    class="w-full" 
                                    v-model="dataLine.ul_qty"
                                    :min="0"
                                    @input="(val) => { onChangeQty(index, val);}"
                                    :disabled="dataLine.qty_loading == dataLine.qty_sales"
                                >
                                </vs-input>
                            </td>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input v-model="dataLine.qty_remaining" :readonly="true" style="width: auto;"></vs-input>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <br>
        <div class="vx-row mb-6">
            <div class="vx-col sm:w-1/1 w-full">
                <div style="
                    position: absolute;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
                    <vs-button class="mr-3 mb-2" @click="handleSubmit">Save</vs-button>
                    <vs-button class="ml-4 mt-2" type="border" color="danger" v-on:click="handleBack">Back</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import vSelect from "vue-select";

export default {
    components: {
        vSelect,
    },
    data() {
        return {
            id: null,
            readonly: true,
            reqCode: null,
            unlCode: null,
            selectedTerritory: null,
            selectedWarehouse: null,
            selectedSales: null,
            loadingDate: null,
            unloadingDate: null,
            dataLines: [
                {
                    unloading_id: 0,
                    item_name: null,
                    item_code: null,
                    qty_loading: 0,
                    qty_sales: 0,
                    qty_remaining: 0,
                    ul_option_hu: [],
                    ul_selected_hu: "",
                    ul_qty: 0,
                    qty_uom: null,
                }
            ],
            renderUom: true,
        }
    },
    mounted() {
        this.id = this.$route.params.id;
        if (this.id) {
            this.getData()
        }
    },
    methods: {
        getData() {
            this.$vs.loading();
            this.$http.get("/api/wms/v1/canvas/unloading/receive-data-form/" + this.id)
            .then((resp) => {
                if (resp.code == 200) {
                    this.reqCode = resp.data.request_doc
                    this.unlCode = resp.data.unloading_doc
                    this.selectedTerritory = resp.data.selected_territory
                    this.selectedWarehouse = resp.data.selected_warehouse
                    this.selectedSales = resp.data.selected_sales
                    this.loadingDate = moment(resp.data.loading_date).toDate()
                    this.unloadingDate = moment(resp.data.unloading_date).toDate()
                    this.dataLines = resp.data.data_lines

                    for (let index = 0; index < this.dataLines.length; index++) {
                        this.onChangeHu(index, this.dataLines[index].ul_selected_hu)
                    }
                    this.$vs.loading.close();
                } else {
                    this.$vs.loading.close();
                    this.$vs.dialog({
                        color: "danger",
                        title: "Something went wrong",
                        text: resp.message,
                    })
                }
            })
            .catch(error => {
                this.$vs.loading.close();
                console.log(error)
                this.$vs.dialog({
                    color: "danger",
                    title: "Something went wrong",
                    text: error,
                })
            });
        },
        handleBack() {
            this.$router.push({
                name: "canvas.unloading",
            });
        },
        handleSubmit() {
            let message = ""
            let skus = []
            this.dataLines.forEach((val) => {
                if (val.ul_selected_hu == "") {
                    if (val.qty_loading != val.qty_sales) {
                        message = "Please select HU for item " + val.item_name
                        return
                    }
                }

                skus.push({
                    unloading_id: val.unloading_id,
                    item_code: val.item_code,
                    unl_hu: val.ul_selected_hu,
                    unl_qty: parseInt(val.ul_qty),
                })
            })

            if (message != "") {
                this.$vs.notify({
                    color: "danger",
                    title: `Error`,
                    text: message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                })
                return false;
            }

            let formData = new FormData();
            formData.append("skus", JSON.stringify(skus))

            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure to save this data?",
                accept: () => {
                    this.$vs.loading();
                    this.$http.post("/api/wms/v1/canvas/unloading/save-unloading/" + this.id, formData)
                    .then((resp) => {
                        if (resp.code == 200) {
                            this.$vs.notify({
                                color: "success",
                                title: "Success",
                                text: resp.message,
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-x-circle",
                            });
                            this.$vs.loading.close();
                            this.handleBack()
                            this.draw++;
                        } else {
                            this.$vs.loading.close();
                            this.$vs.dialog({
                                color: "danger",
                                title: "Something went wrong",
                                text: resp.message,
                            })
                        }
                    })
                    .catch((error) => {
                        this.$vs.loading.close();
                        console.log(error);
                        this.$vs.dialog({
                            color: "danger",
                            title: "Something went wrong",
                            text: error,
                        })
                    });
                }
            });
        },
        handleUpdateFormat(value) {
            let cleanValue = value.toString().replace(/\D/g, '');
            return new Intl.NumberFormat('id-ID').format(cleanValue);
        },
        onChangeQty(index, val) {
            this.dataLines[index].ul_qty = val
            if (this.dataLines[index].ul_qty < 0) {
                this.$vs.notify({
                    color: "danger",
                    title: "Error",
                    text: "Minimum qty is 0",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
            }
        },
        onChangeHu(index, val) {
            this.$http.get("/api/wms/v1/canvas/unloading/get-amount-uom", {
                params: {
                    sku_code: this.dataLines[index].item_code,
                    unit: val,
                }
            })
            .then(resp => {
                if (resp.code == 200) {
                    this.dataLines[index].qty_uom = this.handleUpdateFormat(resp.data)
                    this.renderUom = false
                    this.$nextTick(() => (this.renderUom = true));
                }
            })
            .catch(error => {
                console.log(error)
            });
        },
    },
    computed: {
        formatDate: () => {
            return (val) => moment.utc(val).format("YYYY-MM-DD")
        },
    },
}
</script>